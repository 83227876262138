<template>
  <div style="">
    <a-card title="漏损查询" :bordered="false" style="">
      <div class="table-page-search-wrapper">
        <a-form :form="form" layout="inline">
          <a-row :gutter="[24, 0]">
            <a-col :xxl="12" :sm="24">
              <a-form-item label="选择水厂：">
                <a-cascader
                        v-if="cascaderDefault.length > 0"
                  :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                  :options="tree"
                  placeholder=""
                  :allowClear="false"
                  :defaultValue="cascaderDefault"
                  @change="onCascaderChange"
                />
              </a-form-item>
            </a-col>
            <a-col :xxl="12" :sm="24">
              <a-form-item label="指定区段：">
                <a-select
                  style="width: 200px"
                  placeholder="请选择起点"
                  v-model="queryParam.fromid"
                >
                  <a-select-option v-for="item in fromMonitors" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
                <span style="margin: 0 10px;">
                  至
                </span>
                <a-select
                  style="width: 200px"
                  placeholder="请选择终点"
                  v-model="queryParam.toid"
                >
                  <a-select-option v-for="item in toMonitors" :key="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xxl="12" :sm="24">
              <a-form-item no-prop label="查询周期">
                <div class="flex-row">
                  <a-radio-group v-model="queryParam.timeType" button-style="solid">
                    <a-radio-button value="hour">
                      小时
                    </a-radio-button>
                    <a-radio-button value="day">
                      日
                    </a-radio-button>
                    <a-radio-button value="month">
                      月
                    </a-radio-button>
                  </a-radio-group>
                  <div style="flex:1" class="flex-row">
                    <div v-if="queryParam.timeType === 'hour'">
                      <label style="display:inline-block;margin-left:20px">选择时间：</label>
                      <!--<a-date-picker v-model="queryParam.date" />-->
                        <a-range-picker v-model="rangeH" :show-time="{ format: 'HH' }" format="YYYY-MM-DD HH:00"/>
                    </div>
                    <div v-else-if="queryParam.timeType === 'day'">
                      <label style="display:inline-block;margin-left:20px">选择日期：</label>
                        <a-range-picker format="YYYY-MM-DD" v-model="rangeD"/>
                      <!--<a-month-picker placeholder="请选择月份" v-model="queryParam.date"/>-->
                    </div>
                    <div v-else>
                      <label style="display:inline-block;margin-left:20px">选择月份：</label>
                        <a-range-picker format="YYYY-MM" v-model="rangeM"/>
                      <!--<a-select style="width: 100px" placeholder="请选择年份" v-model="queryParam.date">-->
                        <!--<a-select-option v-for="item in years" :key="item">-->
                          <!--{{item}}-->
                        <!--</a-select-option>-->
                      <!--</a-select>-->
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>

            <a-col :xxl="8" :sm="24">
              <span
                class="table-page-search-submitButtons"
              >
                <a-button type="primary" @click="handleQuery">查询</a-button>
                <!--<a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card title="查询结果" :bordered="false" style="margin-top: 5px;">
      <div slot="extra">
        <a-button type="primary" style="margin-left:10px;" @click="exportReport">
          导出数据
        </a-button>
      </div>
      <a-spin :spinning="confirmLoading">
      <div style="height:300px;" ref="chart"></div>
      </a-spin>
    </a-card>
    <a-card>
      <a-table
              row-key="index"
              :columns="columns"
              :dataSource="data"
              :pagination="pagination"
              :loading="confirmLoading"
      >
        <a slot="leakPercent" slot-scope="text, record">{{
          text
          }}%</a>
        <template slot="index" slot-scope="text, record">
          <div>{{ text }}</div>
        </template>
        <template slot="time" slot-scope="text, record">
          <div>{{ record.start }}</div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from "echarts";
import {getAreaParam,leakQuery,leakQueryExprot,waterfactoryTree,connections} from "@/api/monitor/index";
import { export_json_to_excel } from '@/excel/Export2Excel'

const publicColums = [
  {
    dataIndex: "index",
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" }
  },
  {
    dataIndex: "fromName",
    title: "起点",
    align: "center"
  },
  {
    dataIndex: "toName",
    title: "终点",
    align: "center"
  },
  {
    dataIndex: "time",
    title: "时间",
    align: "center",
    scopedSlots: { customRender: "time" }
  },
  {
    dataIndex: "fromLl",
    title: "送水量(m³)",
    align: "center"
  },
  {
    dataIndex: "toLl",
    title: "送达量(m³)",
    align: "center"
  },
  {
    dataIndex: "leak",
    title: "漏损量(m³)",
    align: "center"
  },
  {
    dataIndex: "leakPercent",
    title: "漏损率",
    align: "center",
    scopedSlots: { customRender: "leakPercent" }
  }
];
export default {
  name: "leakquery",
  data() {
    return {
      form: this.$form.createForm(this),
      confirmLoading: false,
      columns: publicColums,
      data: [],
      pagination: {
        size: "",
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showLessItems: true,
        pageSizeOptions: ["5", "10", "20", "30", "50"],
        onShowSizeChange: (current, pageSize) => {
          this.pageSize = pageSize;
        },
        showTotal: total => `共${total}条`
      },
      tree: [],
      cascaderDefault: [],
      connections: [],
      fromMonitors: [],
      toMonitors:[],
      queryParam: { pageNum: 1, pageSize: 10, orderBy: '', wfid: undefined, fromid: undefined, toid: undefined,timeType: 'hour',date: moment(),start: undefined,end: undefined },
      rangeH: [moment().subtract(5,'hours'),moment()],
      rangeD: [moment().subtract(7,'days'),moment()],
      rangeM: [moment().subtract(7,'months'),moment()],
      years: [2021],
      init: false,
      myChart:null
    };
  },
  created() {
    let param = getAreaParam();
    waterfactoryTree(param).then(r=>{
      this.tree = [r.data]
      this.cascaderDefault = this.getTreeDefaultValue(this.tree)
      console.log(this.cascaderDefault)
      let wfid = this.cascaderDefault[this.cascaderDefault.length - 1]
      this.queryParam.wfid = wfid
    })
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  watch:{
    'queryParam.wfid' (val){
      console.log('watch queryParam.wfid',val)
      connections({wfid:val}).then(r=>{
        this.connections = r.data
        const len = this.connections.length
        let fromArr = []

        for (let i = 0; i < len; i++) {
          const item = this.connections[i]
          const fromId = item.fromid
          const fromName = item.fromName
          const toId = item.toid
          const toName = item.toName
          if(fromArr.indexOf(fromId) === -1){
            fromArr.push({id:fromId,name:fromName})
          }
        }
        this.fromMonitors = fromArr
        this.queryParam.fromid = fromArr[0].id
        console.log('fromArr',fromArr)
      })
    },
    'queryParam.fromid' (val){
      console.log('watch queryParam.fromid',val)
      const len = this.connections.length
      let toArr = []
      for (let i = 0; i < len; i++) {
        const item = this.connections[i]
        const fromId = item.fromid
        const toId = item.toid
        const toName = item.toName
        if(val === fromId){
          toArr.push({id:toId,name:toName})
        }
      }
      this.toMonitors = toArr
      this.queryParam.toid = toArr[0].id
      if(!this.init){
        this.init = true
        this.fetchList()
      }
    },
    'queryParam.timeType'(val){
      switch (val) {
        case 'hour':
          this.queryParam.date = moment()
          this
          break
        case 'day':
          this.queryParam.date = moment()
          break
        case 'month':
          this.queryParam.date = this.years[0]
          break
      }
    }
  },
  methods: {
    resize () {
      if (this.myChart) {
        this.myChart.resize()
      }
    },
    getTreeDefaultValue(tree){
      let arr = []
      let first = tree[0]
      arr.push(first.id)
      while (first.children && first.children.length > 0){
        arr.push(first.children[0].id)
        first = first.children[0]
      }
      return arr
    },
    fetchList(){
      this.confirmLoading = true
      const param = Object.assign({}, this.queryParam)
      switch (param.timeType) {
        case 'hour':
          param.date = moment(param.date).format('YYYY-MM-DD')
          param.start = moment(this.rangeH[0]).format("YYYY-MM-DD HH:mm:00")
          param.end = moment(this.rangeH[1]).format("YYYY-MM-DD HH:mm:00")
          break
        case 'day':
          param.date = moment(param.date).format('YYYY-MM-DD')
          param.start = moment(this.rangeD[0]).format("YYYY-MM-DD")
          param.end = moment(this.rangeD[1]).format("YYYY-MM-DD")
          break
        case 'month':
          param.start = moment(this.rangeM[0]).format("YYYY-MM-DD")
          param.end = moment(this.rangeM[1]).format("YYYY-MM-DD")
          break
      }
      leakQuery(param).then(r=>{
        const data = r.data
        const pageDetail = data.pageDetail
        const list = pageDetail.list
        const pageNum = pageDetail.pageNum
        const pageSize = pageDetail.pageSize
        const len = list.length
        for (let i = 0; i < len; i++) {
          list[i].index = i + 1
          if(param.timeType == 'hour'){
            list[i].start = moment(list[i].start).format('YYYY-MM-DD HH:00')
          }
          // list[i].start = moment(list[i].start).format('YYYY-MM-DD HH:mm:ss')
          // list[i].end = moment(list[i].end).format('YYYY-MM-DD HH:mm:ss')
        }
        this.pagination.current = pageNum
        this.pagination.total = pageDetail.total
        this.pagination.pageSize = pageSize

        this.data = list
        const xdata = data.xdata
        if(param.timeType == 'hour'){
        for (let i = 0; i < xdata.length; i++) {
          xdata[i] = moment(xdata[i]).format('YYYY-MM-DD HH:00')
          }
        }
        this.initChart(xdata,data.series)
        this.confirmLoading = false
      }).catch(err =>{
        this.confirmLoading = false
      })
    },
    exportReport() {
      const param = Object.assign({}, this.queryParam)
      switch (param.timeType) {
        case 'hour':
          param.date = moment(param.date).format('YYYY-MM-DD')
          param.start = moment(this.rangeH[0]).format("YYYY-MM-DD HH:mm:00")
          param.end = moment(this.rangeH[1]).format("YYYY-MM-DD HH:mm:00")
          break
        case 'day':
          param.date = moment(param.date).format('YYYY-MM-DD')
          param.start = moment(this.rangeD[0]).format("YYYY-MM-DD")
          param.end = moment(this.rangeD[1]).format("YYYY-MM-DD")
          break
        case 'month':
          param.start = moment(this.rangeM[0]).format("YYYY-MM-DD")
          param.end = moment(this.rangeM[1]).format("YYYY-MM-DD")
          break
      }
      param.pageNum = 1
      param.pageSize = 1000000
      leakQuery(param).then(res => {
        let list = res.data.pageDetail.list
        if(list){
          const len = list.length
          for (let i = 0;i < len;i++) {
            list[i].index = i + 1
            if(param.timeType == 'hour'){
              list[i].start = moment(list[i].start).format('YYYY-MM-DD HH:00')
            }
            list[i].leakPercent = list[i].leakPercent + '%'
          }
          const tHeader = ['序号', '起点', '终点', '时间','送水量(m³)', '送达量(m³)', '漏损量(m³)', '漏损率']
          const filterVal = ['index', 'fromName', 'toName','start', 'fromLl', 'toLl', 'leak', 'leakPercent']
          const cols = [{wpx:40},{wpx:150},{wpx:150},{wpx:130},{wpx:80},{wpx:80},{wpx:80},{wpx:80}]
          const data = this.formatJson(filterVal, list)
          export_json_to_excel(tHeader, data, '漏损查询列表',cols)
        }
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    handleDownload () {
      const param = Object.assign({}, this.queryParam)
      switch (param.timeType) {
        case 'hour':
          param.date = moment(param.date).format('YYYY-MM-DD')
          break
        case 'day':
          param.date = moment(param.date).format('YYYY-MM-DD')
          break
        case 'month':
          break
      }
      leakQueryExprot(param).then(data => {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', '漏损查询-' + moment().format('YYYY-MM-DD') + '.xlsx')
        link.click()
      }).catch(err => {
        console.log(err)
      })
    },
    onCascaderChange(value){
      console.log('onCascaderChange',value)
     const wfid = value[value.length - 1]
      this.queryParam.wfid = wfid
    },
    initChart(xAxisData,series) {
      var chartDom = this.$refs.chart;
      var myChart = echarts.init(chartDom);
      this.myChart = myChart
      var option;
      let arr = []
      for (let i = 0; i < series.length; i++) {
        arr.push(series[i].name)
      }
      option = {
        grid: {
          top: 35,
          bottom: 40,
          left: 45,
          right: 20,
        },
        legend:{
          left: "right",
          data: arr
        },
        tooltip: {
          trigger: "axis",
          // formatter: function (params) {
          //   console.log('params',params)
          //
          //   return ''
          // }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: "value",
          name: '漏损量(m³)'
        },
        // dataZoom: [{
        //         show: true,
        //         realtime: true,
        //         start: 30,
        //         end: 70,
        //         xAxisIndex: [0, 1]
        //     },
        //     {
        //         type: 'inside',
        //         realtime: true,
        //         start: 30,
        //         end: 70,
        //         xAxisIndex: [0, 1]
        //     }
        // ],
        color:[ //自定义的颜色
          'rgb(19,194,194)',
          'rgb(109,155,250)',
          'red'
        ],
        series: series,
        dataZoom: [{
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 15,
          height: '15',
          bottom: '0',
          zoomLock: false
        }]
      };
      myChart.setOption(option);
    },
    handleQuery () {
      this.queryParam.pageNum = 1
      this.fetchList()
    },
    resetQuery () {
      this.fetchList()
    },
  }
};
</script>

<style scoped>
.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
