import http from "@/plugins/http_monitor";

export function getAreaParam() {
  let admin = localStorage.getItem("admin");
  let areaId = 1;
  let param = {}
    let province = JSON.parse(localStorage.getItem("province"));
    if (province.id) {
      param.provinceId = parseInt(province.id);
      param.level = 1;
    }
    let city = JSON.parse(localStorage.getItem("city"));
    if (city.id) {
      param.cityId = parseInt(city.id);
      param.level = 2;
    }
    let area = JSON.parse(localStorage.getItem("area"));
    if (area.id) {
      param.areaId = parseInt(area.id);
      param.level = 3;
    }
  return param
}

export function getRegion(level) {
  if(level == 3){
    return JSON.parse(localStorage.getItem("area"))
  }else if(level == 2){
    return JSON.parse(localStorage.getItem("city"))
  }else if(level == 1){
    return JSON.parse(localStorage.getItem("province"))
  }
}

export function findRegionById(params) {
  return http({
    url: `/region/findById`,
    method: "get",
    params: params
  });
}

export function findRegionByPid(params) {
  return http({
    url: `/region/findByPid`,
    method: "get",
    params: params
  });
}

export function homeInfo(params) {
  return http({
    url: `/home/info`,
    method: "get",
    params: params
  });
}

export function devicePage(data) {
  return http({
    url: `/device/page`,
    data:data,
    method: "post"
  });
}

export function deviceExport(data) {
  return http({
    url: `/device/export`,
    data:data,
    method: "post",
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function deviceTypeAll() {
  return http({
    url: `/deviceType/all`,
    method: "get"
  });
}

export function deviceTypeAllWithZhibiao() {
  return http({
    url: `/deviceType/allwithzhibiao`,
    method: "get"
  });
}

export function addDeviceType(data) {
  return http({
    url: `/deviceType/add`,
    method: "post",
    data: data
  });
}

export function updateDeviceType(data) {
  return http({
    url: `/deviceType/update`,
    method: "post",
    data: data
  });
}

export function deleteDeviceType(params) {
  return http({
    url: `/deviceType/delete`,
    method: "post",
    params: params
  });
}

export function zhibiaoAll() {
  return http({
    url: `/zhibiao/all`,
    method: "get"
  });
}

export function monitorAll() {
  return http({
    url: `/monitor/all`,
    method: "get"
  });
}

export function monitorList(params) {
  return http({
    url: `/monitor/findByWfid`,
    method: "get",
    params: params
  });
}

export function monitorListByZhibiao(params) {
  return http({
    url: `/monitor/findByZhibiao`,
    method: "get",
    params: params
  });
}

export function contactAll(data) {
  return http({
    url: `/contact/all`,
    method: "post",
    data: data
  });
}

export function addContact(data) {
  return http({
    url: `/contact/add`,
    method: "post",
    data: data
  });
}

export function updateContact(data) {
  return http({
    url: `/contact/update`,
    method: "post",
    data: data
  });
}

export function deleteContact(params) {
  return http({
    url: `/contact/delete`,
    method: "post",
    params: params
  });
}

export function pipeQuery(data) {
  return http({
    url: `/pipe/query`,
    data:data,
    method: "post"
  });
}

export function pipeQueryExprot (data) {
  return http({
    url: `/pipe/query/export`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function pipeCheck(data) {
  return http({
    url: `/pipe/check`,
    data:data,
    method: "post"
  });
}

export function pipeCheckExprot (data) {
  return http({
    url: `/pipe/check/export`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function warnZhibiaoList(params) {
  return http({
    url: `/warn/zhibiaos`,
    method: "get",
    params: params
  });
}

export function warnPage(data) {
  return http({
    url: `/warn/page`,
    method: "post",
    data: data
  });
}

export function groupAll() {
  return http({
    url: `/group/all`,
    method: "get"
  });
}

export function groupList(params) {
  return http({
    url: `/group/findByMid`,
    method: "get",
    params: params
  });
}

export function waterfactoryAll() {
  return http({
    url: `/waterfactory/all`,
    method: "get"
  });
}

export function findWaterfactoryByRegion(params) {
  return http({
    url: `/waterfactory/findByRegion`,
    method: "get",
    params:params
  });
}

export function findByWfid(wfid,zhibiao,zhibiao2) {
  let url = `/monitor/findByWfid?wfid=`+wfid+`&zhibiao=` + zhibiao
  if(zhibiao2 != undefined){
    url += '&zhibiao2=' + zhibiao2
  }
  return http({
    url: url,
    method: "get"
  });
}

export function waterfactoryTree(params) {
  return http({
    url: `/waterfactory/tree`,
    method: "get",
    params: params
  });
}

export function leakAnalysis(data) {
  return http({
    url: `/leak/analysis`,
    data:data,
    method: "post"
  });
}

export function leakAnlysisExprot (data) {
  return http({
    url: `/leak/analysis/export`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function leakQuery(data) {
  return http({
    url: `/leak/query`,
    data:data,
    method: "post"
  });
}

export function leakQueryExprot (data) {
  return http({
    url: `/leak/query/export`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function connections(data) {
  return http({
    url: `/leak/connections`,
    params:data,
    method: "get"
  });
}

export function findGroupVideoByWfid(wfid) {
  return http({
    url: `/video/group/all?wfid=`+wfid,
    method: "get"
  });
}


export function findVideoByGroupid(groupid) {
  return http({
    url: `/video/all?groupid=`+groupid,
    method: "get"
  });
}
export function findZutaiByWfid(wfid) {
  return http({
    url: `/zutai/findByWfid?wfid=`+wfid,
    method: "get"
  });
}

export function addWaterfactory(data) {
  return http({
    url: `/waterfactory/add`,
    method: "post",
    data: data
  });
}

export function updateWaterfactory(data) {
  return http({
    url: `/waterfactory/update`,
    method: "post",
    data: data
  });
}

export function deleteWaterfactoryt(params) {
  return http({
    url: `/waterfactory/delete`,
    method: "post",
    params: params
  });
}

export function addMonitor(data) {
  return http({
    url: `/monitor/add`,
    method: "post",
    data: data
  });
}

export function updateMonitor(data) {
  return http({
    url: `/monitor/update`,
    method: "post",
    data: data
  });
}

export function deleteMonitor(params) {
  return http({
    url: `/monitor/delete`,
    method: "post",
    params: params
  });
}

export function addGroup(data) {
  return http({
    url: `/group/add`,
    method: "post",
    data: data
  });
}

export function updateGroup(data) {
  return http({
    url: `/group/update`,
    method: "post",
    data: data
  });
}

export function deleteGroup(params) {
  return http({
    url: `/group/delete`,
    method: "post",
    params: params
  });
}

export function addVideoGroup(data) {
  return http({
    url: `/video/group/add`,
    method: "post",
    data: data
  });
}

export function updateVideoGroup(data) {
  return http({
    url: `/video/group/update`,
    method: "post",
    data: data
  });
}

export function deleteVideoGroup(params) {
  return http({
    url: `/video/group/delete`,
    method: "post",
    params: params
  });
}

export function addVideo(data) {
  return http({
    url: `/video/add`,
    method: "post",
    data: data
  });
}

export function updateVideo(data) {
  return http({
    url: `/video/update`,
    method: "post",
    data: data
  });
}

export function deleteVideo(params) {
  return http({
    url: `/video/delete`,
    method: "post",
    params: params
  });
}

export function zutaiAll(params) {
  return http({
    url: `/zutai/findByWfid`,
    method: "get",
    params: params
  });
}

export function addZutai(data) {
  return http({
    url: `/zutai/add`,
    method: "post",
    data: data
  });
}

export function updateZutai(data) {
  return http({
    url: `/zutai/update`,
    method: "post",
    data: data
  });
}

export function deleteZutai(params) {
  return http({
    url: `/zutai/delete`,
    method: "post",
    params: params
  });
}

export function zutaiComponentAll(params) {
  return http({
    url: `/zutai/component/all`,
    method: "get",
    params: params
  });
}

export function addZutaiComponent(data) {
  return http({
    url: `/zutai/component/add`,
    method: "post",
    data: data
  });
}

export function updateZutaiComponent(data) {
  return http({
    url: `/zutai/component/update`,
    method: "post",
    data: data
  });
}

export function deleteZutaiComponent(params) {
  return http({
    url: `/zutai/component/delete`,
    method: "post",
    params: params
  });
}

export function findDevicesByMid(params) {
  return http({
    url: `/device/findByMid`,
    method: "get",
    params: params
  });
}

export function addDevice(data) {
  return http({
    url: `/device/add`,
    method: "post",
    data: data
  });
}

export function updateDevice(data) {
  return http({
    url: `/device/update`,
    method: "post",
    data: data
  });
}

export function deleteDevice(params) {
  return http({
    url: `/device/delete`,
    method: "post",
    params: params
  });
}


export function findByWfid1(wfid,zhibiao,zhibiao2) {
  let url = `/monitor/findByWfid1?wfid=`+wfid+`&zhibiao=` + zhibiao
  if(zhibiao2 != undefined){
    url += '&zhibiao2=' + zhibiao2
  }
  return http({
    url: url,
    method: "get"
  });
}
