import axios from "axios";
import qs from "qs";
// import router from '@/router/'
// import {
//   getToken
//   // setToken
// } from "@/utils/auth";
// import message from "@/utils/pnotify";
// let baseUrl = "http://dev.waterss.cn:9130/api/basic-info";
// let baseUrl = "https://waterss.cn/api/basic-info";
let baseUrl = 'http://waterfactory.ez-info.cn/zw'
// let baseUrl = `/api/water-plant-monitor`;
// let baseUrl = `http://localhost:9160/api/water-plant-monitor`;
const _https = axios.create({
  baseURL: baseUrl
});
_https.interceptors.request.use(
  config => {
    // if (getToken()) {
    //     config.headers['authen'] = getToken()
    // }
    //只针对get方式进行序列化
    if (config.method === "get") {
      config.paramsSerializer = function(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }
    if (localStorage.getItem("token")) {
      config.headers["X-Access-Token"] = localStorage.getItem("token");
    }
    //config.headers["Content-Type"] = "application/json;charset=UTF-8";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
_https.interceptors.response.use(
  response => {
    var accessToken = response.headers['X-Access-Token']
    if(accessToken) {
      console.log("accessToken==========="+accessToken)
      localStorage.setItem("token",accessToken) ;
    }
    const url = response.config.url
    console.log('url',url)
    if (url.indexOf('export') !== -1 ) {
      return response.data
    }
    if (
      response.headers["Content-type"] == "multipart/form-data;charset=UTF-8"
    ) {
      return response.data;
    }
    return response.data;
  },
  error => {
    Promise.reject(error);
    console.log(error);
  }
);

// async function https(options) {
//   const response = await _https(options);
//   console.log('options',options)
//   console.log(response);
//   const url = options.url
//   console.log('url',url)
//   if (url.indexOf('export') !== -1 ) {
//     return response
//   }
//   if (response.status == 200) {
//     let result = response.data;
//     if (result.data?.token && result.code == 0) {
//       localStorage.setItem("token", result.data.token);
//       localStorage.setItem("userName", result.data.nick_name);
//     }
//     return result;
//   }
//   if (response.status == 401) {
//     localStorage.removeItem("token");
//     let host = window.location.origin;
//     window.location.replace(host);
//   }
//   if (response.status == 200 || response.error == 0) {
//     return response.data;
//   } else {
//     if (response.status == 500) {
//       throw new Error(response.error);
//     }
//   }
// }

export default _https;
